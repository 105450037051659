<div class="relative flex justify-content-end"

>
  <div class="menu-icon">
    <div class="corner-top-right"></div>
    <div class="menu-icon-label" [class.hover]="hover()">
      <i [class]="icon()"></i>
    </div>
    <div class="corner-bottom-right"></div>
  </div>
</div>

